<template>
  <main class="form-container">
    <p class="-xltb title">Upload File</p>
    <hr class="column-span-8" style="align-self: end" />
    <div class="column-span-4 flex-main-info">
      <div class="flex-row project-info">
        <p class="-stb">{{ project.ref }}</p>
        <p class="-stb">{{ project.name }}</p>
      </div>
      <div class="flex-row unit-info">
        <p class="-str">{{ unit.ref }}</p>
        <p class="-str">{{ unit.name }}</p>
      </div>
    </div>

    <section class="column-span-4 flex-row new-btn-container">
      <div class="new-btn"><p class="-xstb">New Document</p></div>
      <div class="new-btn"><p class="-xstb">New Version</p></div>
    </section>
    <hr class="column-span-8" />

    <div class="column-span-4 flex-column">
      <p class="-xstb">Project <span title="Required field">*</span></p>
      <div class="flex-column">
        <input
          disabled
          type="text"
          ref="projectRef"
          v-model="project.name"
          class="-xstb"
        />
        <p class="-xstb error-msg">This field is required!</p>
      </div>
    </div>

    <div class="column-span-4 flex-column">
      <p class="-xstb">PM <span title="Required field">*</span></p>
      <div class="flex-column">
        <input
          type="text"
          ref="pmRef"
          v-model="pm"
          @blur="validateString(pm, 'pmRef')"
          class="-xstb"
        />
        <p class="-xstb error-msg">This field is required!</p>
      </div>
    </div>

    <div class="column-span-4 flex-column">
      <p class="-xstb">Originator</p>
      <input
        disabled
        type="text"
        ref="originatorRef"
        v-model="originator"
        class="-xstb"
      />
    </div>

    <div class="column-span-4 flex-column">
      <p class="-xstb">FI <span title="Required field">*</span></p>
      <div class="flex-column">
        <input
          type="text"
          ref="fiRef"
          v-model="fi"
          @blur="validateString(fi, 'fiRef')"
          class="-xstb"
        />
        <p class="-xstb error-msg">This field is required!</p>
      </div>
    </div>

    <div class="column-span-4 flex-column">
      <p class="-xstb">Unit <span title="Required field">*</span></p>
      <div class="flex-column">
        <input
          disabled
          type="text"
          ref="unitRef"
          v-model="unit.name"
          class="-xstb"
        />
        <p class="-xstb error-msg">This field is required!</p>
      </div>
    </div>

    <div class="column-span-4 flex-column">
      <p class="-xstb">Suitability</p>
      <div class="flex-column">
        <input
          type="text"
          ref="suitabilityRef"
          v-model="suitability"
          class="-xstb"
        />
      </div>
    </div>

    <div class="column-span-4 flex-column">
      <p class="-xstb">Level <span title="Required field">*</span></p>
      <div class="flex-column">
        <input
          type="text"
          ref="levelRef"
          v-model="level"
          @blur="validateString(level, 'levelRef')"
          class="-xstb"
        />
        <p class="-xstb error-msg">This field is required!</p>
      </div>
    </div>

    <div class="column-span-4 flex-column">
      <p class="-xstb">Revision</p>
      <input type="text" ref="revisionRef" v-model="revision" class="-xstb" />
    </div>

    <div class="column-span-4 flex-column">
      <p class="-xstb">Agent <span title="Required field">*</span></p>
      <div class="flex-column">
        <input
          disabled
          type="text"
          ref="agentRef"
          v-model="agent"
          class="-xstb"
        />
        <p class="-xstb error-msg">This field is required!</p>
      </div>
    </div>

    <div class="column-span-4 flex-column">
      <p class="-xstb">Number</p>
      <input type="text" ref="numberRef" v-model="number" class="-xstb" />
    </div>
    <hr class="column-span-8" style="align-self: center" />
    <div class="column-span-8 flex-column">
      <p class="-xstb">File name:</p>
      <input
        disabled
        type="text"
        :value="`${project.ref}-${originator}-(volume)-${level}-(type)-${agent}-30_10_30-${number}-${suitability}-${revision}`"
        class="-xstb"
        style="text-align: center"
      />
    </div>

    <div
      @click="
        handleClick([
          'projectRef',
          'unitRef',
          'levelRef',
          'agentRef',
          'pmRef',
          'fiRef',
        ])
      "
      class="column-span-8 upload"
    >
      <p class="-xstb">Upload</p>
    </div>
  </main>
</template>

<script>
import { validateForm } from "../../../../helpers/formValidations";
import { removeErrInputText } from "../../../../helpers/styleToggler";
export default {
  data() {
    return {
      project: {
        name: "Northern University Campus",
        ref: "NB_001",
      },
      originator: "BIMMS",
      unit: {
        name: "Block 3",
        ref: "SC4.0",
      },
      level: "",
      agent: "Project Manager",
      pm: "",
      fi: "",
      suitability: "",
      revision: "",
      number: "",
      fileName: "NB_001-B-V1-01-M3-30_10_30-000-S1-P02",
      //Project - Originator - Volume or system - Levels and locations - Type - Role - Classification - Number - Suitability(meta-data) - Revision(meta-data)
    };
  },
  methods: {
    handleClick(refsArray) {
      //prepare info for validateForm()
      const infoToValidate = [
        this.project.name,
        this.unit.name,
        this.level,
        this.agent,
        this.pm,
        this.fi,
      ];

      let arrayOfElements = [];
      refsArray.forEach((element) => {
        arrayOfElements.push(this.$refs[element]);
      });

      if (!this.validateForm(arrayOfElements, infoToValidate)) return;

      console.log("validated, send request"); //write api code here
    },
    validateForm(arrayOfElements, infoToValidate) {
      const result = validateForm(arrayOfElements, infoToValidate);
      return result;
    },
    validateString(value, inputRef) {
      if (!value.length) return;
      removeErrInputText(this.$refs[inputRef]);
    },
  },
  computed: {
    remainingChars() {
      let maxChars = 255;
      return maxChars - this.description.length;
    },
  },
};
</script>

<style scoped>
.form-container {
  height: 100%;
  width: 100%;
  padding-block: 16px;
  padding-inline: 32px;
  display: grid;
  grid-template-columns: auto max-content;
  /* grid-template-rows: repeat(14, 1fr); */
  gap: 8px;
}
.column-span-8 {
  grid-column: span 8;
}
.column-span-4 {
  grid-column: span 4;
}

.title {
  grid-column: span 8;
}
.flex-main-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 4px;
}
.flex-row {
  display: flex;
  align-items: center;
  gap: 16px;
}
.form-btns-group {
  grid-column: span 8;
}
.project-info,
.unit-info {
  justify-content: space-between;
  padding-inline: 8px;
}
.new-btn-container {
  justify-self: end;
  align-self: center;
  gap: 16px;
}
.new-btn {
  background: var(--gray6);
  padding: 8px;
  border-radius: 4px;
  user-select: none;
  color: var(--gray3);
}

.upload {
  padding: 8px;
  border-radius: 4px;
  justify-self: end;
  transition: 150ms ease;
  width: 152px;
  background: var(--primary);
  color: var(--white1);
}
.upload:hover {
  background: var(--success);
  cursor: pointer;
}
input,
select,
option,
textarea {
  border: 1px solid var(--gray5);
  border-radius: 4px;
  padding: 8px;
  outline: none;
}
input,
select {
  height: 38px;
  width: 100%;
}
hr {
  border: none;
  border-top: 1px solid var(--gray6);
  margin-block: 8px;
}
span {
  color: var(--error);
}
/**EFFECTS **/
input:focus,
select:focus,
option:focus,
textarea:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
}
p {
  user-select: none;
  white-space: nowrap;
}

@media screen and (max-width: 719px) {
  .column-span-4 {
    grid-column: span 8;
  }
}
</style>